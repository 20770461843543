import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-2b419b32")
const _hoisted_1 = { class: "tax-card" }
const _hoisted_2 = {
  key: 1,
  class: "other"
}
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { class: "other" }
const _hoisted_5 = {
  key: 0,
  class: "other"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_field = _resolveComponent("van-field")!
  const _component_van_cell_group = _resolveComponent("van-cell-group")!
  const _component_van_radio = _resolveComponent("van-radio")!
  const _component_van_radio_group = _resolveComponent("van-radio-group")!
  const _component_van_picker = _resolveComponent("van-picker")!
  const _component_van_popup = _resolveComponent("van-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.hasDeleteIcon)
      ? (_openBlock(), _createElementBlock("i", {
          key: 0,
          class: "iconfont icon-cangpeitubiao_shanchu",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.deleteTax()))
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_van_cell_group, {
      title: "司法管辖区",
      border: false
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_field, {
          name: "taxCountry",
          modelValue: _ctx.form.taxCountry,
          "onUpdate:modelValue": _ctx.changeTaxCountry,
          maxlength: "100",
          placeholder: "输入司法管辖区",
          rules: [{ required: true, message: '请输入司法管辖区' }]
        }, null, 8, ["modelValue", "onUpdate:modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_van_cell_group, {
      title: "税务编号",
      border: false
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_radio_group, {
          modelValue: _ctx.form.hasTaxNumber,
          "onUpdate:modelValue": _ctx.changeHasTaxNumber,
          direction: "horizontal"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.taxOption, (item) => {
              return (_openBlock(), _createBlock(_component_van_radio, {
                key: item.name,
                name: item.name
              }, {
                icon: _withCtx(() => [
                  _createElementVNode("i", {
                    class: "iconfont icon-xuanze1",
                    style: _normalizeStyle({ color: _ctx.form.hasTaxNumber == item.name ? '#FFB400' : '#A5B2DC' })
                  }, null, 4)
                ]),
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.text), 1)
                ]),
                _: 2
              }, 1032, ["name"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue", "onUpdate:modelValue"])
      ]),
      _: 1
    }),
    (_ctx.form.hasTaxNumber == 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_van_field, {
            name: "taxNumber",
            modelValue: _ctx.form.taxNumber,
            "onUpdate:modelValue": _ctx.changeTaxNumber,
            placeholder: "输入税务编号",
            rules: [{ required: true, message: '请输入税务编号' }]
          }, null, 8, ["modelValue", "onUpdate:modelValue"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_van_field, {
              name: "reason",
              modelValue: _ctx.form.reason,
              "onUpdate:modelValue": _ctx.onReasonConfirm,
              placeholder: "请选择理由",
              readonly: "",
              rows: "1",
              autosize: "",
              type: "textarea",
              rules: [{ required: true, message: '请选择理由' }],
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showReasonPickerToggle(true))),
              "right-icon": "arrow-down"
            }, null, 8, ["modelValue", "onUpdate:modelValue"]),
            _createVNode(_component_van_popup, {
              show: _ctx.showReasonPicker,
              "onUpdate:show": _cache[3] || (_cache[3] = ($event: any) => (_ctx.showReasonPicker = $event)),
              round: "",
              position: "bottom"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_van_picker, {
                  title: "请选择",
                  columns: _ctx.reasons,
                  onConfirm: _ctx.onReasonConfirm,
                  onCancel: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showReasonPickerToggle(false)))
                }, null, 8, ["columns", "onConfirm"])
              ]),
              _: 1
            }, 8, ["show"])
          ]),
          (_ctx.form.reasonType == 'B')
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_van_field, {
                  name: "reasonDesc",
                  modelValue: _ctx.form.reasonDesc,
                  "onUpdate:modelValue": _ctx.changeReasonDesc,
                  placeholder: "请输入原因",
                  rules: [{ required: true, message: '请输入原因' }]
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]))
            : _createCommentVNode("", true)
        ]))
  ]))
}