
import { defineComponent, ref, watch } from 'vue'
import { useToggle } from '@vant/use'

export default defineComponent({
  props: {
    hasDeleteIcon: {
      type: Boolean,
      default: false
    },
    taxIndex: {
      type: Number,
      default: 0
    },
    form: {
      type: Object,
      default: () => ({
        taxCountry: '',
        taxNumber: '',
        reasonType: '',
        reasonDesc: '',
        hasTaxNumber: 1
      })
    }
  },
  setup (props, { emit }) {
    // const form = reactive({
    //   taxCountry: '',
    //   taxNumber: '',
    //   reasonType: '',
    //   reasonDesc: '',
    //   hasTaxNumber: 1
    // });
    // const reason = ref('');
    // const hasReason:any = props.reasons.filter((val:any) => val.value === props.form.reasonType);
    // reason.value = hasReason.length !== 0 ? hasReason[0].text : '';
    const reasons = ref([
      { value: 'A', text: '理由 A – 本人的居留司法管辖区并没有向其居民发出税务编号' },
      { value: 'B', text: '理由 B – 本人不能取得税务编号' },
      { value: 'C', text: '理由 C – 居留司法管辖区的主管机关不需要本人披露税务编号' }
    ])
    const changeTaxCountry = (val:string) => {
      emit('changeTaxCountry', val)
    }
    const changeHasTaxNumber = (val:string) => {
      emit('changeHasTaxNumber', val)
    }
    const changeReasonDesc = (val:string) => {
      emit('changeReasonDesc', val)
    }
    const changeTaxNumber = (val:string) => {
      emit('changeTaxNumber', val)
    }
    // 税务理由picker
    const [showReasonPicker, showReasonPickerToggle] = useToggle()
    const onReasonConfirm = (val: { text: string; value: string }) => {
      // reason.value = val?.text;
      emit('changeReasonType', val.value)
      emit('changeReason', val.text)
      showReasonPickerToggle(false)
    }
    const taxOption = [
      { text: '能提供', name: 1 },
      { text: '不能提供', name: 0 }
    ]
    const deleteTax = () => {
      emit('deleteTax', props.taxIndex)
    }
    watch(
      () => props.form,
      newVal => {
        // const hasReason: any = props.reasons.filter((val:any) => val.value === props.form.reasonType);
        // reason.value = hasReason.length ? hasReason[0].text : '';
        emit('changeTax', newVal, props.taxIndex)
      },
      { deep: true, immediate: true }
    )

    return {
      changeTaxCountry,
      changeHasTaxNumber,
      changeReasonDesc,
      changeTaxNumber,
      // reason,
      reasons,
      taxOption,
      showReasonPicker,
      showReasonPickerToggle,
      onReasonConfirm,
      deleteTax
    }
  }
})
